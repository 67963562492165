<template>
    <div class="col-md-3 card-metric">
      <h4 class="section-subheading title-metric">{{ title }} </h4>
      <p class="section-heading text-uppercase text-metric" :data-target="value"></p> <!-- Corrigido o binding para data-target -->
    </div>
  </template>
  
  <script>
  export default {
    name: "MetricCard",
    props: {
      title: {
        type: String,
        required: true,
      },
      value: {
        type: Number,
        required: true,
      }
    },
    mounted() {
      this.updateCount();
    },
    methods: {
      updateCount() {
        const counter = this.$el.querySelector('.text-metric');
        const target = +counter.getAttribute('data-target');
        const speed = 200; // Velocidade da animação em milissegundos
  
        const updateCount = () => {
          const count = +counter.innerText;
  
          // Calcular a velocidade da animação
          const inc = target / speed;
  
          // Verificar se a contagem já atingiu o destino
          if (count < target) {
            // Adicionar o incremento ao contador
            counter.innerText = Math.ceil(count + inc);
            // Chamar a função novamente após um intervalo
            setTimeout(updateCount, 1);
          } else {
            counter.innerText = target; // Definir o valor final do contador
          }
        };
  
        updateCount(); // Iniciar a contagem
      },
    },
  };
  </script>
  
  <style lang="css" scoped>
  .card-metric {
    background-color: rgb(148, 148, 148);
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
  }
  
  .title-metric {
    color: black;
    margin-bottom: 1rem;
  }
  
  .text-metric {
    font-size: 30px;
  }
  </style>
  
  