<template>
    <div class="container">
        <div class="row featurette">
            <div class="col-md-7 order-md-2">
                <h2 class="featurette-heading">SOBRE:<span class="text-muted"> PLATAFORMA MARANHÃO
                        PROFISSIONALIZADO</span></h2>
                <p class="lead">Criada em 2020 pelo Governo do Estado do Maranhão através da Secretaria de Estado da
                    Educação, a Plataforma Maranhão Profissionalizado oferta cursos gratuitos de qualificação
                    profissional online. Nosso objetivo é promover a geração de emprego e renda através da ampliação das
                    oportunidades de aprendizado em diversas áreas.

                </p>
            </div>
            <div class="col-md-5 order-md-1">
                <img class="featurette-image img-fluid mx-auto" alt="500x500"
                    src="images/SOBRE.png"
                    data-holder-rendered="true">
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "SobreSectionVue"
}
</script>

<style lang="css" scoped></style>