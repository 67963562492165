<template>
    <a href="https://wa.me/5598985064913?text=Ol%C3%A1%2C+tenho+uma+d%C3%BAvida%21+">
      <i class="fa-brands fa-square-whatsapp btn-wpp"></i>
    </a>
  </template>
  
  <script>
  export default {
    name: "WhatsappButton",
  };
  </script>
  
  <style>
  .btn-wpp {
    position: fixed;
    bottom: 10px;
    right: 10px;
    font-size: 70px;
    cursor: pointer;
    border-radius: 5px;
    z-index: 1000;
    color: rgb(0, 209, 38);
  }
  </style>
  