<template>
  <div>
    <div class="portfolio-item">
      <a class="portfolio-link">
        <img class="img-fluid" :src="curso.imageUrl" :alt="curso.title" />
      </a>
      <div class="portfolio-caption">
        <div class="portfolio-caption-heading">{{ curso.title }}</div>
        <!-- <div class="portfolio-caption-subheading text-muted">{{ subTitle }}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CursoItem",
  props: {
    curso: {
      type: Object,
      required: true,
    },
  }
};
</script>

<style>
.portfolio-caption-heading{
  color: rgb(53, 53, 53);
}
</style>
