var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('section',{staticClass:"page-section",attrs:{"id":"sobre"}},[_c('SobreSection')],1),_c('section',{staticClass:"page-section bg-light",staticStyle:{"margin":"20px 0","padding":"20px 0"},attrs:{"id":"services"}},[_c('div',{staticClass:"container"},[_vm._m(4),_c('div',{staticClass:"row text-center align-items-stretch justify-center"},[_c('FeatureCard',{attrs:{"icon":"fa-solid fa-graduation-cap","description":"Certificado com selo institucional e com código para validação.","title":"Certificação Reconhecida"}}),_c('FeatureCard',{attrs:{"icon":"fa-solid fa-clock","description":"Aulas acessíveis de qualquer a lugar, a qualquer hora, ajustando-se à sua rotina.\n      ","title":"Estudo Flexivel"}}),_c('FeatureCard',{attrs:{"icon":"fa-solid fa-book-open-reader","description":"Explore videoaulas, quizzes, fóruns e materiais de apoio para uma experiência de aprendizagem dinâmica.","title":"Aprendizagem Interativa"}}),_c('FeatureCard',{attrs:{"icon":"fa-solid fa-address-card","description":"Adquira habilidades valiosas, explore conteúdos envolventes e alcance o sucesso profissional.","title":"Educação Acessível e Gratuita"}})],1)])]),_c('VideoYt',{attrs:{"video-id":"iMA9NGnfLJA","title":"Programa Maranhão Profissionalizado"}}),_c('section',{staticClass:"page-section",attrs:{"id":"services"}},[_c('div',{staticClass:"container"},[_vm._m(5),_c('div',{staticClass:"row text-center align-items-stretch justify-center"},[_c('MetricCard',{attrs:{"title":"Matrículas Realizadas","value":105306}}),_c('MetricCard',{attrs:{"title":"Horas de Conteúdo","value":243}}),_c('MetricCard',{attrs:{"title":"Atividades Complementares","value":15641}}),_c('MetricCard',{attrs:{"title":"Cursos Disponíveis","value":38}})],1)])]),_vm._m(6),_c('FooterVue')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-imagens"},[_c('a',{attrs:{"href":"https://cta.ma.gov.br"}},[_c('img',{staticClass:"logo",attrs:{"src":"https://cta.ma.gov.br/wp-content/uploads/2023/03/logo_cta_site_white.webp","alt":"Logo CTA"}})]),_c('a',{attrs:{"href":"https://www.educacao.ma.gov.br"}},[_c('img',{staticClass:"logo",attrs:{"src":"https://www.educacao.ma.gov.br/wp-content/uploads/2023/03/logo.png","alt":"Logo Educação"}})]),_c('a',{attrs:{"href":"https://www.saude.ma.gov.br"}},[_c('img',{staticClass:"logo",attrs:{"src":"https://www.saude.ma.gov.br/wp-content/uploads/2023/03/NOVA_SES_PRETA_HOR.png","alt":"Logo Saúde"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('div',{staticClass:"container"},[_c('a',{attrs:{"href":"/docs/ementa_comed.pdf","target":"_blank"}},[_c('img',{staticClass:"banner",attrs:{"src":"images/cta/banner1.png","alt":"Banner do Curso"}})]),_c('h1',[_vm._v("Curso de Treinamento para Operador de Suporte Médico")]),_c('p',[_vm._v("Capacite-se para realizar missões aeromédicas com segurança e eficiência.")]),_c('a',{staticClass:"btn",attrs:{"href":"#inscricao"}},[_vm._v("Entrar na plataforma")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"objetivo",attrs:{"id":"objetivo"}},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v("Objetivo do Curso")]),_c('p',[_vm._v("Capacitar profissionais de saúde para desempenhar a função de Operador de Suporte Médico no Centro Tático Aéreo, qualificando-os para realizar missões aeromédicas com segurança e eficiência tanto em helicópteros quanto em aviões do CTA.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"conteudo",attrs:{"id":"conteudo"}},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v("Conteúdo Programático")]),_c('div',{staticClass:"curriculo"},[_c('h3',[_vm._v("Currículo Teórico")]),_c('ul',[_c('li',[_c('strong',[_vm._v("Legislação e Manuais:")]),_vm._v(" 8 horas")]),_c('li',[_c('strong',[_vm._v("Equipes de Saúde e Paciente:")]),_vm._v(" 8 horas")]),_c('li',[_c('strong',[_vm._v("Segurança de Voo:")]),_vm._v(" 8 horas")]),_c('li',[_c('strong',[_vm._v("Conhecimentos Técnicos:")]),_vm._v(" 8 horas")]),_c('li',[_c('strong',[_vm._v("Procedimentos de Emergência:")]),_vm._v(" 8 horas")])])]),_c('div',{staticClass:"curriculo"},[_c('h3',[_vm._v("Currículo Prático")]),_c('ul',[_c('li',[_c('strong',[_vm._v("Embarque e Desembarque de Paciente:")]),_vm._v(" 4 horas")]),_c('li',[_c('strong',[_vm._v("Evacuação de Emergência:")]),_vm._v(" 2 horas")]),_c('li',[_c('strong',[_vm._v("Extinção de Fogo e Controle de Situações de Emergência:")]),_vm._v(" 4 horas")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h2',{staticClass:"section-heading text-uppercase",staticStyle:{"color":"black"}},[_vm._v("Indicadores de Sucesso")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h2',{staticClass:"section-heading text-uppercase"},[_vm._v("Números que refletem confiança!")]),_c('h3',{staticClass:"section-subheading text-muted"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"inscricao",attrs:{"id":"inscricao"}},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v("Inscreva-se Agora")]),_c('p',[_vm._v("Ao final do curso, os participantes serão submetidos a uma avaliação teórica e/ou prática conforme o parágrafo (f), seção 90.221 do RBAC90. A certificação será realizada no modelo de aeronave na qual serão desempenhadas as operações aéreas do CTA, e o registro será arquivado na pasta individual do operador de suporte médico.")]),_c('a',{staticClass:"btn",attrs:{"href":"https://maranhaoprofissionalizado.iema.ma.gov.br/app/#/login/"}},[_vm._v("Plataforma Maranhão Profissionalizado")])])])
}]

export { render, staticRenderFns }