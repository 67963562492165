export default [
    {
        id: 1,
        imageUrl: "images/capas-cursos/10 - Aprendendo a Ensinar Online.jpg",
        title: "Aprendendo a Ensinar Online",
        eixo_tecnologico: "",
        modalidade: "",
        carga_horaria: "",
        publico_alvo: "",
        duracao: "",
        apresentacao: "",
        objetivos: {
            geral: "",
            especificos: [
                "",
                "",
                "",
                ""
            ]
        },
        programa_do_curso: [
            {
                modulo: "",
                titulo: ""
            },
            {
                modulo: "",
                titulo: ""
            },
            {
                modulo: "",
                titulo: ""
            },
            {
                modulo: "",
                titulo: ""
            }
        ]
    },
    {
        id: 2,
        imageUrl: "images/capas-cursos/11 - Aproveitamento Integral dos Alimentos.jpg",
        title: "Aproveitamento Integral dos Alimentos",
        eixo_tecnologico: "",
        modalidade: "",
        carga_horaria: "",
        publico_alvo: "",
        duracao: "",
        apresentacao: "",
        objetivos: {
            geral: "",
            especificos: [
                "",
                "",
                "",
                ""
            ]
        },
        programa_do_curso: [
            {
                modulo: "",
                titulo: ""
            },
            {
                modulo: "",
                titulo: ""
            },
            {
                modulo: "",
                titulo: ""
            },
            {
                modulo: "",
                titulo: ""
            }
        ]
    },
    {
        id: 3,
        imageUrl: "images/capas-cursos/12 - Assistente Administrativo.jpg",
        title: "Assistente Administrativo",
        eixo_tecnologico: "",
        modalidade: "",
        carga_horaria: "",
        publico_alvo: "",
        duracao: "",
        apresentacao: "",
        objetivos: {
            geral: "",
            especificos: [
                "",
                "",
                "",
                ""
            ]
        },
        programa_do_curso: [
            {
                modulo: "",
                titulo: ""
            },
            {
                modulo: "",
                titulo: ""
            },
            {
                modulo: "",
                titulo: ""
            },
            {
                modulo: "",
                titulo: ""
            }
        ]
    },
    {
        id: 4,
        imageUrl: "images/capas-cursos/13 - Assistente de Recursos Humanos.jpg",
        title: "Assistente de Recursos Humanos",
        eixo_tecnologico: "Gestão e Negócios",
        modalidade: "EAD",
        carga_horaria: "100h",
        publico_alvo: "Comunidade em geral",
        duracao: "15 dias",
        apresentacao: "O Curso de Assistente de Recursos Humanos tem por objetivo formar profissionais socialmente responsáveis, capazes de desempenhar atividades nos processos de recrutamento e seleção, treinamento e desenvolvimento, cargos, salários e benefícios, avaliação de desempenho e formação de equipes, levando em consideração aspectos da legislação trabalhista, bem como orientar sobre a importância da saúde e segurança no trabalho.",
        objetivos: {
            geral: "Proporcionar acesso a conhecimentos teóricos e práticos do Curso de Assistente Recursos Humanos com intuito de preparar profissionais para atuar na área de RH e departamento de pessoal",
            especificos: [
                "Formar profissionais para executar rotinas de processos do setor de Recursos Humanos de uma organização",
                "Realizar atividades de apoio ao planejamento, execução, avaliação e controle dos processos de recrutamento e seleção, treinamento e desenvolvimento, avaliação de desenvolvimento, avaliação de desempenho, progressão funcional, cargos, salários e segurança no trabalho"
            ]
        },
        programa_do_curso: [
            {
                modulo: "Módulo 1",
                titulo: "INTRODUÇÃO AO RECURSO HUMANO",
            },
            {
                modulo: "Módulo 2",
                titulo: "RECRUTAMENTO E SELEÇÃO DE PESSOAS"
            },
            {
                modulo: "Módulo 3",
                titulo: "SALÁRIOS, BENEFÍCIOS E RELAÇÕES SINDICAIS"
            },
            {
                modulo: "Módulo 4",
                titulo: "TREINAMENTO E DESENVOLVIMENTO DE PESSOAL"
            },
            {
                modulo: "Módulo 5",
                titulo: "ANÁLISE DE CARGOS E FUNÇÕES"
            },
            {
                modulo: "Módulo 6",
                titulo: "QUALIDADE DE VIDA NO TRABALHO"
            }
        ]
    },
    {
        id: 5,
        imageUrl: "images/capas-cursos/14 - Auxiliar de Serviços Jurídicos.jpg",
        title: "Auxiliar de Serviços Jurídicos"
    },
    {
        id: 6,
        imageUrl: "images/capas-cursos/15 - Doces Tradicionais e Finos.jpg",
        title: "Doces Tradicionais e Finos"
    },
    {
        id: 7,
        imageUrl: "images/capas-cursos/16 - Elaboração de Documentos Oficiais.jpg",
        title: "Elaboração de Documentos Oficiais"
    },
    {
        id: 8,
        imageUrl: "images/capas-cursos/17 - Elaboração de Projetos Audiovisuais e Produção Executiva.jpg",
        title: "Elaboração de Projetos Audiovisuais e Produção Executiva"
    },
    {
        id: 9,
        imageUrl: "images/capas-cursos/18 - Ensino Híbrido_ Personalização e Tecnologia na Educação.jpg",
        title: "Ensino Híbrido_ Personalização e Tecnologia na Educação"
    },
    {
        id: 10,
        imageUrl: "images/capas-cursos/19 - Espaços Educadores Sustentáveis.jpg",
        title: "Espaços Educadores Sustentáveis"
    },
    {
        id: 11,
        imageUrl: "images/capas-cursos/20 - Estética.jpg",
        title: "Estética"
    },
    {
        id: 12,
        imageUrl: "images/capas-cursos/21 - Excel Básico.jpg",
        title: "Excel Básico"
    },
    {
        id: 13,
        imageUrl: "images/capas-cursos/22 - Gestão Socioemocional Frente às Crises e Inseguranças.jpg",
        title: "Gestão Socioemocional Frente às Crises e Inseguranças"
    },
    {
        id: 14,
        imageUrl: "images/capas-cursos/23 - Informática Básica.jpg",
        title: "Informática Básica"
    },
    {
        id: 15,
        imageUrl: "images/capas-cursos/24 - LIBRAS Básico.jpg",
        title: "LIBRAS Básico"
    },
    {
        id: 16,
        imageUrl: "images/capas-cursos/25 - Mini Jardins.jpg",
        title: "Mini Jardins"
    },
    {
        id: 17,
        imageUrl: "images/capas-cursos/26 - Panificação.jpg",
        title: "Panificação"
    },
    {
        id: 18,
        imageUrl: "images/capas-cursos/27 - Produtos Cimentícios Sustentáveis.jpg",
        title: "Produtos Cimentícios Sustentáveis"
    },
    {
        id: 19,
        imageUrl: "images/capas-cursos/28 - Recepcionista.jpg",
        title: "Recepcionista"
    },
    {
        id: 20,
        imageUrl: "images/capas-cursos/29 - Roteiro e Escrita Criativa.jpg",
        title: "Roteiro e Escrita Criativa"
    },
    {
        id: 21,
        imageUrl: "images/capas-cursos/30 - Sala de Aula Digital Interativa.jpg",
        title: "Sala de Aula Digital Interativa"
    },
    {
        id: 22,
        imageUrl: "images/capas-cursos/31 - Secretariado.jpg",
        title: "Secretariado"
    },
    {
        id: 23,
        imageUrl: "images/capas-cursos/32 - Serigrafia Customizada.jpg",
        title: "Serigrafia Customizada"
    },
    {
        id: 24,
        imageUrl: "images/capas-cursos/33 - Tortas de Vitrine.jpg",
        title: "Tortas de Vitrine"
    }
];
