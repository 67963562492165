<template>
    <div>
        <!-- Menu de Imagens -->
        <div class="menu-imagens">
            <a href="https://cta.ma.gov.br">
                <img src="https://cta.ma.gov.br/wp-content/uploads/2023/03/logo_cta_site_white.webp" alt="Logo CTA" class="logo">
            </a>
            <a href="https://www.educacao.ma.gov.br">
                <img src="https://www.educacao.ma.gov.br/wp-content/uploads/2023/03/logo.png" alt="Logo Educação" class="logo">
            </a>
            <a href="https://www.saude.ma.gov.br">
                <img src="https://www.saude.ma.gov.br/wp-content/uploads/2023/03/NOVA_SES_PRETA_HOR.png" alt="Logo Saúde" class="logo">
            </a>
        </div>
        
            <header class="header">
                <div class="container">
                    <a href="/docs/ementa_comed.pdf" target="_blank">
                        <img src="images/cta/banner1.png" alt="Banner do Curso" class="banner">
                    </a>
                    <h1>Curso de Treinamento para Operador de Suporte Médico</h1>
                    <p>Capacite-se para realizar missões aeromédicas com segurança e eficiência.</p>
                    <a href="#inscricao" class="btn">Entrar na plataforma</a>
                </div>
            </header>

        <section class="objetivo" id="objetivo">
            <div class="container">
                <h2>Objetivo do Curso</h2>
                <p>Capacitar profissionais de saúde para desempenhar a função de Operador de Suporte Médico no Centro
                    Tático
                    Aéreo, qualificando-os para realizar missões aeromédicas com segurança e eficiência tanto em
                    helicópteros quanto em aviões do CTA.</p>
            </div>
        </section>

        <section class="conteudo" id="conteudo">
            <div class="container">
                <h2>Conteúdo Programático</h2>
                <div class="curriculo">
                    <h3>Currículo Teórico</h3>
                    <ul>
                        <li><strong>Legislação e Manuais:</strong> 8 horas</li>
                        <li><strong>Equipes de Saúde e Paciente:</strong> 8 horas</li>
                        <li><strong>Segurança de Voo:</strong> 8 horas</li>
                        <li><strong>Conhecimentos Técnicos:</strong> 8 horas</li>
                        <li><strong>Procedimentos de Emergência:</strong> 8 horas</li>
                    </ul>
                </div>
                <div class="curriculo">
                    <h3>Currículo Prático</h3>
                    <ul>
                        <li><strong>Embarque e Desembarque de Paciente:</strong> 4 horas</li>
                        <li><strong>Evacuação de Emergência:</strong> 2 horas</li>
                        <li><strong>Extinção de Fogo e Controle de Situações de Emergência:</strong> 4 horas</li>
                    </ul>
                </div>
            </div>
        </section>

        <!-- Sobre Section -->
        <section class="page-section" id="sobre">
            <SobreSection />
        </section>
        <!-- Features Section -->
        <section class="page-section bg-light" style="margin: 20px 0; padding: 20px 0;" id="services">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase" style="color: black;">Indicadores de Sucesso</h2>
                </div>
                <div class="row text-center align-items-stretch justify-center">
                    <FeatureCard icon="fa-solid fa-graduation-cap"
                        description="Certificado com selo institucional e com código para validação."
                        title="Certificação Reconhecida" />
                    <FeatureCard icon="fa-solid fa-clock" description="Aulas acessíveis de qualquer a lugar, a qualquer hora, ajustando-se à sua rotina.
          " title="Estudo Flexivel" />
                    <FeatureCard icon="fa-solid fa-book-open-reader"
                        description="Explore videoaulas, quizzes, fóruns e materiais de apoio para uma experiência de aprendizagem dinâmica."
                        title="Aprendizagem Interativa" />
                    <FeatureCard icon="fa-solid fa-address-card"
                        description="Adquira habilidades valiosas, explore conteúdos envolventes e alcance o sucesso profissional."
                        title="Educação Acessível e Gratuita" />
                </div>
            </div>
        </section>

        <VideoYt video-id="iMA9NGnfLJA" title="Programa Maranhão Profissionalizado" />

        <section class="page-section" id="services">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Números que refletem confiança!</h2>
                    <h3 class="section-subheading text-muted"></h3>
                </div>
                <div class="row text-center align-items-stretch justify-center">
                    <MetricCard title="Matrículas Realizadas" :value=105306 />
                    <MetricCard title="Horas de Conteúdo" :value=243 />
                    <MetricCard title="Atividades Complementares" :value=15641 />
                    <MetricCard title="Cursos Disponíveis" :value=38 />
                </div>
            </div>
        </section>
        <section class="inscricao" id="inscricao">
            <div class="container">
                <h2>Inscreva-se Agora</h2>
                <p>Ao final do curso, os participantes serão submetidos a uma avaliação teórica e/ou prática conforme o
                    parágrafo (f), seção 90.221 do RBAC90. A certificação será realizada no modelo de aeronave na qual
                    serão
                    desempenhadas as operações aéreas do CTA, e o registro será arquivado na pasta individual do
                    operador de
                    suporte médico.</p>
                <a href="https://maranhaoprofissionalizado.iema.ma.gov.br/app/#/login/" class="btn">Plataforma Maranhão
                    Profissionalizado</a>
            </div>
        </section>
        <FooterVue />
    </div>
</template>

<script>
// import CTACarousel from '@/components/cta/CTACarousel'
import FooterVue from '@/components/Footer.vue'
import FeatureCard from '@/components/FeatureCard.vue'
import SobreSection from '@/components/SobreSection.vue'
import MetricCard from '@/components/MetricCard.vue'
import VideoYt from '@/components/VideoYt.vue'


export default {
    components: {
        FooterVue,
        SobreSection,
        FeatureCard,
        MetricCard,
        VideoYt
    }
}
</script>

<style scoped>
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    color: #333;
    background-color: #f8f8f8;
}

.menu-imagens {
    display: flex;
    background: #0073e6;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}

.menu-imagens .logo {
    max-height: 60px;
    margin: 0 15px;
    object-fit: contain;
}

.header {
    background: #0073e6;
    color: #fff;
    padding: 100px 0;
    text-align: center;
    position: relative;
}

.header .container {
    position: relative;
    z-index: 2;
}

.header .banner {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 10px;
}

.header h1 {
    margin: 0;
    font-size: 2.5em;
}

.header p {
    font-size: 1.2em;
    margin: 10px 0 20px;
}

.btn {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1.2em;
    color: #fff;
    background: #005bb5;
    text-decoration: none;
    border-radius: 5px;
    transition: background 0.3s;
}

.btn:hover {
    background: #003d80;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

section {
    padding: 60px 0;
    text-align: center;
}

section h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.objetivo,
.inscricao {
    padding: 40px 0;
}

.curriculo {
    margin: 20px 0;
}

.curriculo h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.curriculo ul {
    list-style: none;
    padding: 0;
    text-align: left;
    max-width: 600px;
    margin: 0 auto;
}

.curriculo ul li {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
}
</style>
