import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FaqView from '@/views/FaqView.vue'
import CopMedView from '@/views/CopMedView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqView
  },
  {
    path: '/copmed',
    name: 'CopMed',
    component: CopMedView
  }
]

const router = new VueRouter({
  routes
})

export default router
