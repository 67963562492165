<template>
    <div>
        <NavVue />
        <FaqVue />
        <FooterVue />
        <WhatsappButton />
    </div>
</template>

<script>
import NavVue from '@/components/Nav.vue'
import FooterVue from '@/components/Footer.vue'
import WhatsappButton from '@/components/WhatsappButton.vue'
import FaqVue from '@/components/Faq.vue'
    export default {
        name: "FaqView",
        components:{
            NavVue,
            FaqVue,
            WhatsappButton,
            FooterVue,
        }
    }
</script>

<style scoped>

</style>